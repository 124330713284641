import React from "react";
import useToken from "../../assets/useToken";
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';



export default function Logout() {
  let navigate = useNavigate();
  const { token } = useToken();
  async function logOut() {
    return fetch("https://api-lms.nourkadri.me/api/logout", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => data.json())
      
     
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await logOut();
    window.localStorage.clear()
    navigate("/");
    window.location.reload(false);

  };
  return ( 
   <LogoutIcon sx={{ fontSize: 50,color: "red"}} onClick={handleSubmit}/>
   )
}
