import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import "./AddAdminDialog.scss";
import avatar from "../../../assets/images/blank-profile-pic.png";
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
import $ from "jquery";

const Input = styled("input")({
  display: "none",
});

export default function AddAdminDialog(props) {
  const clearState = () => {
    setName("");
    setEmail("");
    setPassword("");
    setPic("");
  };
  const formData = new FormData();
  const [name, setName] = React.useState();
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const [image, setImage] = React.useState(avatar);
  const [pic, setPic] = React.useState();

  const handleChangeAddAdmin = (e) => {
    setPic(e.target.files[0]);
    const [file] = e.target.files;
    setImage(URL.createObjectURL(file));
  };
  const handleSave = (e) => {
    formData.append("picture", pic);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    props.adminInfo(formData);
    props.yes(true);
    props.set(false);
    setImage(avatar)
    clearState();
  };

  const handleCancel = () => {
    props.set(false);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{props.title}</DialogTitle>
        <div className="container">
          <div className="avatar-upload">
            <div className="avatar-edit">
              <input
                type="file"
                id="imageUpload"
                accept=".png, .jpg, .jpeg"
                onChange={handleChangeAddAdmin}
              />
              <label htmlFor="imageUpload">
                <FaceRetouchingNaturalIcon
                  sx={{ fontSize: 35, color: "blue" }}
                />
              </label>
            </div>
            <div className="avatar-preview">
              <div
                id="imagePreview"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
            </div>
          </div>
        </div>
        <DialogContent>
          <DialogContentText>{props.description}</DialogContentText>
          <form autoComplete="off">
            <TextField
              margin="dense"
              fullWidth
              id="name"
              label="Name"
              variant="outlined"
              name="name"
              type="text"
              size="small"
              autoComplete="new-email"
              inputProps={{
                autoComplete: "new-email",
              }}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="dense"
              fullWidth
              id="email"
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              size="small"
              autoComplete="new-email"
              inputProps={{
                autoComplete: "new-email",
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              fullWidth
              id="secret-create-password"
              label="Password"
              variant="outlined"
              name="secret-password"
              type="password"
              size="small"
              autoComplete="new-password"
              inputProps={{
                autoComplete: "new-password",
              }}
              onChange={(e) => setPassword(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
