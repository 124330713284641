import React from "react";
import "./App.css";
import jwt_decode from "jwt-decode";

import Login from "./Components/Login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import useToken from "./assets/useToken";
import Admins from "./Components/Admins/Admins";
import Sidebar from "./Components/Sidebar/Sidebar";
import Class from "./Components/Class/Class";
import Attendance from "./Components/Attendence/Attendance";
import Reports from "./Components/Reports/Reports";
import Section from "./Components/Sections/Section";
import Students from "./Components/Sudents/Student";

function App() {
  const { token, setToken } = useToken();
  if (!token) {
    return <Login setToken={setToken} />;
  }
  let decodedToken = jwt_decode(token);
  let currentDate = new Date();
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return <Login setToken={setToken} />;
  }

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Sidebar put={<Dashboard />} />
              </>
            }
          />
          <Route
            path="/admins"
            element={
              <>
                <Sidebar put={<Admins />} />{" "}
              </>
            }
          />
          <Route
            path="/classes"
            element={
              <>
                {" "}
                <Sidebar put={<Class />} />{" "}
              </>
            }
          />
          <Route
            path="/sections"
            element={
              <>
                <Sidebar put={<Section/>} />{" "}
              </>
            }
          />
          <Route
            path="/students"
            element={
              <>
                {" "}
                <Sidebar put={<Students/>} />{" "}
              </>
            }
          />
          <Route
            path="/attendances"
            element={
              <>
                <Sidebar put={<Attendance />} />{" "}
              </>
            }
          />
          <Route
            path="/reports"
            element={
              <>
                <Sidebar put={<Reports />} />{" "}
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;