import React, { useState, useEffect } from "react";
import useToken from "../../assets/useToken";
import Table from "../Table/Table";
import Loader from "../Loader/Loader";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AlertDialog from "../Dialog/Dialog";
import Alerts from "../Alert/Alert";
import DialogForm from "./DialogForm/DialogForm";
import Avatar from "@mui/material/Avatar";
import "./Admins.css";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import AddAdminDialog from "./AddAdminDialog/AddAdminDialog";
import Button from "@mui/material/Button";

export default function Admins() {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState();
  const [email, setEmail] = React.useState();
  const [picture, setPicture] = React.useState();
  const [formPic, setFormPic] = React.useState();
  const [adminInfo, setAdminInfo] = React.useState();
  const [alert, setAlert] = React.useState(false);
  const [alertStatus, setAlertStatus] = React.useState();
  const [data, setData] = useState();
  const [id, setId] = useState();
  const [mess, setMess] = useState();
  const { token } = useToken();
  const [yesOrNo, setYesOrNo] = React.useState(false);
  const [YesOrNoUpdate, setYesOrNoUpdate] = React.useState(false);
  const [YesOrNoAdd, setYesOrNoAdd] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);
  const [openFormAdd, setOpenFormAdd] = React.useState(false);
  const [error, setError] = React.useState("");
  useEffect(() => {
    if (yesOrNo == true) {
      (async () => {
        return await fetch(`https://api-lms.nourkadri.me/api/admin/delete?id=${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((data) => data.json())
          .then(setAlertStatus("success"))
          .then((data) => setMess(data));
      })();
      setAlert(true);
      setYesOrNo(false);
    }

    setId(id);
    if (yesOrNo == false) {
    }
  }, [yesOrNo]);

  useEffect(() => {
    if (YesOrNoAdd == true) {
      fetch("https://api-lms.nourkadri.me/api/register", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: adminInfo,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wrong");
        })
        .then((responseJson) => {
          if (responseJson.hasOwnProperty("success")) {
            setMess(responseJson.message);
            setAlertStatus("success");
            setAlert(true);
          }
          if (responseJson.hasOwnProperty("status")) {
            setAlertStatus("warning");
            setMess(responseJson.status);
            setAlert(true);
          }
          if (responseJson.hasOwnProperty("error")) {
            setAlertStatus("error");
            if (responseJson.error.name != undefined) {
              setMess(responseJson.error.name);
            }
            if (responseJson.error.email != undefined) {
              setMess(responseJson.error.email);
            }
            if (responseJson.error.password != undefined) {
              setAlertStatus("warning");
              setMess(responseJson.error.password);
            }
            setAlert(true);
          }

          return responseJson;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setYesOrNoAdd(false);
  }, [YesOrNoAdd]);

  useEffect(() => {
    if (YesOrNoUpdate == true) {
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formPic,
      };
      fetch("https://api-lms.nourkadri.me/api/admin/update", requestOptions)
        .then((response) => response.json())
        .then((data) => setError(data));
      setYesOrNoUpdate(false);
    }
  }, [YesOrNoUpdate]);
  if (error.success === "Admin Updated!") {
    setAlertStatus("success");
    setMess("Admin Updated!");
    setAlert(true);
    setError("");
  }
  if (error.error) {
    setAlertStatus("error");
    setMess(error.error.email);
    setAlert(true);
    setError("");
  }
  if (error.taken) {
    setAlertStatus("error");
    setMess(error.taken);
    setAlert(true);
    setError("");
  }

  useEffect(() => {
    (async () => {
      return await fetch("https://api-lms.nourkadri.me/api/get_user", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => data.json())
        .then((data) => setData(data));
    })();
  }, [id, yesOrNo, YesOrNoUpdate, YesOrNoAdd,alert]);

  const Delete = (id) => () => {
    setOpen(true);
    setId(id);
  };
  const OpenCard = (params) => () => {};

  const Edit = (params) => () => {
    setOpenForm(true);
    setName(params.name);
    setEmail(params.email);
    setPicture(params.picture);
    setId(params.id);
  };
  const addAdmin = () => () => {
    setOpenFormAdd(true);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "name",
      headerName: "Admins name",
      width: 300,
      renderCell: (params) => {
        return (
          <>
            <Avatar src={params.row.picture} />
            &nbsp;&nbsp; {params.row.name}
          </>
        );
      },
    },
    { field: "email", headerName: "email", width: 350 },
    { field: "super", headerName: "super", width: 150 },
    {
      field: "actions1",
      headerName: "Edit",
      type: "actions",
      width: 190,
      renderCell: (params) => {
        return (
          <Button  onClick={Edit(params.row)} variant="contained" color="primary" startIcon={<EditIcon />}>
            Edit
          </Button>
        );
      }
    },
    {
      field: "actions",
      headerName: "Delete",
      type: "actions",
      width: 300,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={Delete(params.id)}
          >
            Delete
          </Button>
        );
      },
    },

   
    {
      field: "info",
      headerName: "Information",
      type: "actions",
      width: 200,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<InfoIcon sx={{ fontSize: 25, color: "green" }} />}
          label="Delete"
          onClick={OpenCard(params.row)}
        />,
      ],
    },
    
  ];

  if (data == undefined) {
    return <Loader />;
  }
  if (data) {
    return (
      <div className="admin_page">
        <div className="admin_logo">
          <AdminPanelSettingsRoundedIcon
            sx={{ fontSize: 100, color: "blue" }}
          />
        </div>

        <div className="btn_add_admin">
          <button onClick={addAdmin()}>
            <AddIcon sx={{ fontSize: 40, color: "blue" }} />
          </button>
        </div>
        <Table rows={data} columns={columns} />
        <AlertDialog
          mess={"You are trying delete Admin ! are you sure?"}
          open={open}
          setOpen={setOpen}
          yes={setYesOrNo}
        />
        <Alerts
          open={alert}
          messAlert={mess}
          status={alertStatus}
          set={setAlert}
          time={3000}
          title={alertStatus}
        />
        <DialogForm
          set={setOpenForm}
          yes={setYesOrNoUpdate}
          open={openForm}
          email={email}
          picture={picture}
          name={name}
          title={"Edit Admin"}
          description={"Type here a new information "}
          setName={setName}
          setEmail={setEmail}
          setPicture={setPicture}
          FormPic={setFormPic}
          id={id}
        />
        <AddAdminDialog
          set={setOpenFormAdd}
          yes={setYesOrNoAdd}
          open={openFormAdd}
          title={"Add Admin"}
          description={"Type here your information "}
          adminInfo={setAdminInfo}
        />
      </div>
    );
  }
}
