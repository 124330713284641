import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useToken from "../../assets/useToken";
import Loader from "../Loader/Loader";
import "./Attendance.css";
import Table from "../Table/Table";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Alerts from "../Alert/Alert";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EditAttendanceDialog from "./ShowAttendence";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import DialogEditAttendance from "./DialogEditAttemdance";
import { random } from "@mui/x-data-grid-generator";

export default function Attendance() {
  const date = new Date();
  const [month, day, year] = [
    date.getMonth(),
    date.getDate(),
    date.getFullYear(),
  ];
  const dateConvert = date.toLocaleDateString("sv-SE");
  const attendance = [];
  const formData = new FormData();
  const { token } = useToken();
  const [classes, setClasses] = React.useState([]);
  const [sections, setSections] = React.useState([]);
  const [section_id, setSection_id] = React.useState([]);
  const [id, setId] = React.useState(null);
  const [enable, setEnable] = React.useState(true);
  const [students, setStudents] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [alertStatus, setAlertStatus] = React.useState("error");
  const [mess, setMess] = useState();
  const [classs, setClasss] = useState();

  const handleChange = (event) => {
    setClasss(event.target.value);
    fetch(
      `https://api-lms.nourkadri.me/api/section/getSectionInClass?id=${event.target.value}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        setSections(responseJson);

        return responseJson;
      });
    setEnable(false);
  };

  const openDialog = (event) => {
    setOpen(true);
  };
  const save = (event) => {
    var myJsonString = JSON.stringify(attendance);
    formData.append("test", myJsonString);
    fetch(`https://api-lms.nourkadri.me/api/attendance/takeAttendance`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        setMess(responseJson.success);
        setAlertStatus("success");
        setAlert(true);
      });
    // attendance = [];
    
  };

  const handleChangeSt = () => {
   
  };

  useEffect(() => {
    fetch(
      `https://api-lms.nourkadri.me/api/section/students_in_section_id?id=${section_id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        if (typeof responseJson[0] !== "undefined") {
          return setStudents(responseJson[0].students);
        }
      });
  }, [section_id,alert]);
  useEffect(() => {
    fetch("https://api-lms.nourkadri.me/api/class/get", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        setClasses(responseJson);

        return responseJson;
      });
  }, [id]);

  const columns = [
    { field: "id", headerName: "ID Student", width: 100 },
    { field: "first_Name", headerName: "First Name", width: 350 },
    { field: "last_Name", headerName: "Last Name", width: 350 },
    
    {
      field: "actions",
      headerName: "Status",
      type: "actions",
      width: 300,
      renderCell: (params) => {
        return (
          <>
            <FormControl>
              {(params.row.updated_at.slice(0, -17)) == dateConvert ? (
                <h1>already taken</h1>
              ) : (
                <RadioGroup
                  onChange={(e) =>
                    attendance.push({
                      student_id: params.id,
                      status: e.target.value,
                    })
                  }
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="attend"
                    control={<Radio />}
                    label="Attend"
                  />
                  <FormControlLabel
                    value="late"
                    control={<Radio />}
                    label="Late"
                  />
                  <FormControlLabel
                    value="absent"
                    control={<Radio />}
                    label="Absent"
                  />
                </RadioGroup>
              )}

              <FormControl>
                <></>
              </FormControl>
            </FormControl>
          </>
        );
      },
    },
  ];
  if (typeof classes === "undefined") {
    return <Loader />;
  }
  return (
    <div className="select__section">
      <div className="admin_logo">
        <NotificationsActiveIcon sx={{ fontSize: 100, color: "blue" }} />
      </div>
      <FormControl sx={{ m: 1, minWidth: 110 }}>
        <InputLabel id="class">Class</InputLabel>
        <Select
          defaultValue=""
          labelId="class"
          id="class"
          onChange={handleChange}
          autoWidth
          label="Classes"
        >
          {classes.map((cl) => (
            <MenuItem value={cl.id} key={cl.class_name}>
              {cl.class_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 110 }}>
        <InputLabel id="section">Section</InputLabel>
        <Select
          defaultValue=""
          labelId="section"
          id="section"
          onChange={(e)=>setSection_id(e.target.value)}
          autoWidth
          label="Sections"
          disabled={enable}
        >
          {sections.map((s) => (
            <MenuItem value={s.id} key={s.section_name}>
              {s.section_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="save__btn">
        <Button
          sx={{ background: "green", fontSize: 20 }}
          onClick={save}
          variant="contained"
          endIcon={<SendIcon />}
          size="small"
        >
          Save
        </Button>
        <div className="edit__btn">
          <Button
            sx={{ background: "blue", fontSize: 20 }}
            variant="contained"
            size="small"
            onClick={openDialog}
          >
            Show
          </Button>
        </div>
      </div>

      <Table rows={students} columns={columns} />
      <Alerts
        open={alert}
        messAlert={mess}
        status={alertStatus}
        set={setAlert}
        time={3000}
        title={alertStatus}
      />
      <EditAttendanceDialog
        set={setOpen}
        open={open}
        classes={classes}
        sections={sections}
        handleChange={handleChange}
        enable={enable}
        classs={classs}
      />
    </div>
  );
}
