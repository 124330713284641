/* App.js */
import React, { Component } from 'react';
import CanvasJSReact from '../../assets/chart/canvasjs.react';
import  { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import useToken from "../../assets/useToken";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import Alerts from '../Alert/Alert';
import Loader from '../Loader/Loader';
import './Reports.css';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

 
export default function Reports() {	
    const [open, setOpen] = React.useState(false);
    const [alertStatus, setAlertStatus] = React.useState("error");
    const [mess, setMess] = useState();
    const { token } = useToken();
    const [absent, setAbsent] = React.useState(1);
    const [attend, setAttend] = React.useState(1);
    const [late, setLate] = React.useState(1);
    const [numberOdStudents, setNnumberOdStudents] = React.useState(1);
    const [classes, setClasses] = useState();
    const [class_id, setClass_id] = useState();
    const [sections, setSections] = React.useState([]);
    const [enable, setEnable] = React.useState(true);
    const [value, setValue] = React.useState(new Date());
    const [section_id, setSection_id] = React.useState(new Date());
    const options = {
			animationEnabled: true,
			exportEnabled: true,
			theme: "light1", // "light1", "dark1", "dark2"
			title:{
				text: `Number of students :${numberOdStudents}`
                
			},
			data: [{
				type: "pie",
				indexLabel: "{label}: {y}",		
				startAngle: -90,
				dataPoints: [
					{ y: absent, label: "Absent" },
					{ y: late, label: "Late" },
					{ y: attend, label: "Attend" },
				]
			}]
		}
    const handleChangeSt = (event) => {
       setSection_id(event.target.value)
      };
      useEffect(() => {
        fetch("https://api-lms.nourkadri.me/api/class/get", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Something went wrong");
          })
          .then((responseJson) => {
            setClasses(responseJson);
            return responseJson;
          });
      }, []);
      const handleChange = (event) => {
        setClass_id(event.target.value)
        fetch(
          `https://api-lms.nourkadri.me/api/section/getSectionInClass?id=${event.target.value}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Something went wrong");
          })
          .then((responseJson) => {
            setSections(responseJson);
    
            return responseJson;
          });
        setEnable(false);
      };
		
		const getReports=()=>{
            fetch(
                `https://api-lms.nourkadri.me/api/reports/attendance_reports?date=${value.toLocaleDateString("sv-SE")}&section_id=${section_id}&class_id=${class_id}`,
                {
                  method: "POST",
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  throw new Error("Something went wrong");
                })
                .then((responseJson) => {
                  if (typeof responseJson.late !== "undefined") {
                    setLate(responseJson.late)
                    setAbsent(responseJson.absent)
                    setAttend(responseJson.attend)
                    setNnumberOdStudents(responseJson.numberOfStudents)
                    setOpen(true)
                    setMess("the report receved!")
                    setAlertStatus('success')
                  
                  }
                });
            };
            if (classes == undefined) {
              return <Loader />;
            }
		return (
		<div className='reports_page'>
          <FormControl sx={{ m: 1, minWidth: 110 }}>
        <InputLabel id="class">Class</InputLabel>
        <Select
          defaultValue=""
          labelId="class"
          id="class"
         onChange={handleChange}
          autoWidth
          label="Classes"
        >
          {classes.map((cl) => (
            <MenuItem value={cl.id} key={cl.class_name}>
              {cl.class_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 110 }}>
        <InputLabel id="section">Section</InputLabel>
        <Select
          defaultValue=""

          labelId="section"
          id="section"
        onChange={handleChangeSt}
          autoWidth
          label="Sections"
          disabled={enable}
        >
          {sections.map((s) => (
            <MenuItem value={s.id} key={s.section_name}>
              {s.section_name}
              
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DatePicker
          disableFuture
          label="Responsive"
          openTo="day"
          views={['day', 'month', 'year']}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField sx={{width: '13.5%'}} {...params} />}
        />
      </Stack>
    </LocalizationProvider>
    <Button
          sx={{ background: "blue", fontSize: 20 }}
          variant="contained"
          size="small"
          onClick={getReports}
          
        >
          Get Report 
        </Button>
			<CanvasJSChart sx={{hight: '1000'}} options = {options} 
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
            <Alerts
        open={open}
        messAlert={mess}
        status={alertStatus}
        set={setOpen}
        time={3000}
        title={alertStatus}
      />
		</div>
		);
	}

 
