import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useToken from "../../../assets/useToken";
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
import { set } from "date-fns";

const DialogEditStudent = (props) => {
  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [phone, setPhone] = React.useState();
  const [email, setEmail] = React.useState();
  const [dob, setDob] = React.useState();
  const [pic, setPic] = React.useState();
  const [class_id, setClass_id] = useState();
  const [sections, setSections] = React.useState([]);
  const [enable, setEnable] = React.useState(true);
  const [selectClass, setSelectClass] = useState();
  const [selectSection, setSelectSection] = useState();

  //data of fetch
  const [dataClass, setDataClass] = useState();
  const [dataSection, setDataSection] = useState();

  //save for edit
  const [saveEdit, setSaveEdit] = useState(false);

  const [dataStudents, setDataStudents] = useState();

  const handleCancel = () => {
    props.set(false); //la yskr l dialog
  };

  const handleSave = () => {
    props.set(false); //la yskr l dialog
    setSaveEdit(true);
  };

  //   function getStudents() {
  //     fetch(`https://api-lms.nourkadri.me/api/students/get`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     })
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error(
  //             `This is an HTTP error: The status is ${response.status}`
  //           );
  //         }
  //         return response.json();
  //       })

  //       .then((actualData) => setDataStudents(actualData))
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   }
  useEffect(() => {
  
    if (saveEdit == true) {
      fetch(
        `https://api-lms.nourkadri.me/api/students/update?id=${props.id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            // '_method': 'PUT',
            first_Name: firstName,
            last_Name: lastName,
            email: email,
            phone: phone,
            date_of_birth: dob,
            picture: pic,
            classs_id: selectClass,
            section_id: selectSection,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }

          return response.json();
        })
        // .then((responseJson) => {
        //   console.log(responseJson);
        //   if (responseJson.hasOwnProperty("section_name")) {
        //     // setMess(responseJson.done);
        //     // setAlertStatus("success");
        //     // setAlert(true);
        //     console.log("updated");
        //   }
        //   return responseJson;
        // })

        .catch((err) => {
          console.log(err.message);
        });
      setSaveEdit(false);
      //   getStudents();
    }
    // props.setID("")
    props.setRerender(true);
  }, [saveEdit, props.set]);

  const handleClass = (event) => {
    setSelectClass(event.target.value)
    fetch(
      `https://api-lms.nourkadri.me/api/section/getSectionInClass?id=${event.target.value}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        setDataSection(responseJson);

        return responseJson;
      });
    setEnable(false);
    setEnable(false);
    handleChange();
  };

  const handleSection = (event) => {
    setSelectSection(event.target.value);
  };
  const { token } = useToken();

  //fetch classes
  function fetchDataClass() {
    fetch(`https://api-lms.nourkadri.me/api/class/get`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        return response.json();
      })

      .then((actualData) => setDataClass(actualData))
      .catch((err) => {
        console.log(err.message);
      });
  }
  useEffect(() => {
    fetchDataClass();
  }, [props.open]);

  //fetch data sections
  const handleChange = (event) => {};

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle sx={{ textAlign: "center" }}>Edit Student</DialogTitle>
        <DialogContent>
          <div className="container">
            <div className="avatar-upload">
              <div className="avatar-edit">
                <input
                  type="file"
                  id="imageUpload"
                  //onChange={handleChange}
                  accept=".png, .jpg, .jpeg"
                />
                <label htmlFor="imageUpload">
                  <FaceRetouchingNaturalIcon
                    sx={{ fontSize: 35, color: "blue" }}
                  />
                </label>
              </div>
              <div className="avatar-preview">
                <div
                  id="imagePreview"
                  style={{ backgroundImage: `url(${props.picture})` }}
                ></div>
              </div>
            </div>
          </div>
          <TextField
            // value={firstName}
            autoFocus
            margin="dense"
            id="name"
            label="First Name"
            //   placeholder={secName}
            type="name"
            fullWidth
            variant="standard"
            onChange={(e) => setFirstName(e.target.value)}
          />

          <TextField
            //   value
            autoFocus
            margin="dense"
            id="name"
            label="Last Name"
            //   placeholder={secName}
            type="name"
            fullWidth
            variant="standard"
            onChange={(e) => setLastName(e.target.value)}
          />

          <TextField
            //   value
            autoFocus
            margin="dense"
            id="name"
            label="Phone"
            //   placeholder={secName}
            type="name"
            fullWidth
            variant="standard"
            onChange={(e) => setPhone(e.target.value)}
          />

          <TextField
            //   value
            autoFocus
            margin="dense"
            id="name"
            label="Email"
            //   placeholder={secName}
            type="name"Z
            fullWidth
            variant="standard"
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            margin="dense"
            id="date_of_birth"
            type="date"
            fullWidth
            variant="standard"
            onChange={(e) => setDob(e.target.value)}
          />

          <FormControl sx={{ m: 1, minWidth: 100 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Class
            </InputLabel>
            <Select
              // labelId="demo-simple-select-label"
              // id="demo-simple-select"
              label="Class"
              value={selectClass}
              onChange={handleClass}
            >
              {dataClass &&
                dataClass.map((ele, i) => {
                  return (
                    <MenuItem key={i} value={ele.id}>
                      {ele.class_name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 100 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Section
            </InputLabel>
            <Select
              // labelId="demo-simple-select-label"
              // id="demo-simple-select"
              disabled={enable}
              label="section"
              value={selectSection}
              onChange={(e) => setSelectSection(e.target.value)}
            >
              {dataSection &&
                dataSection.map((ele, i) => {
                  return (
                    <MenuItem key={i} value={ele.id}>
                      {ele.section_name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogEditStudent;
