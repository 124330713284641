import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from '@mui/material/AlertTitle';



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Alerts(props) {
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    props.set(false)
      };
return (
    <div>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar open={props.open} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} autoHideDuration={props.time} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity={props.status}
              sx={{ width: "100%" }}
            >
            <AlertTitle>{props.title}</AlertTitle>
              {props.messAlert}
            </Alert>
          </Snackbar>
        </Stack>
    </div>
  );
}