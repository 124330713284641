import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useToken from "../../assets/useToken";
import Alerts from "../Alert/Alert";
export default function DialogEditAttendance(props) {

  const {token} =useToken();
  const [status, setStatus] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [alertStatus, setAlertStatus] = React.useState("error");
  const [mess, setMess] = React.useState();
  const handleSave = () => {
    
    fetch(`https://api-lms.nourkadri.me/api/attendance/edit?id=${props.id}&status=${status}`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
       if (responseJson.id!=undefined){
        setMess("updated!");
        setAlertStatus("success");
        setAlert(true);
       }
      });
  };

  const handleCancel = () => {
    props.set(false);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.description}</DialogContentText>
          <RadioGroup
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => setStatus(e.target.value) }
          >
            <FormControlLabel
              value="attend"
              control={<Radio />}
              label="Attend"
            />
            <FormControlLabel value="late" control={<Radio />} label="Late" />
            <FormControlLabel
              value="absent"
              control={<Radio />}
              label="Absent"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleCancel}>Exit</Button>
        </DialogActions>
        <Alerts
        open={alert}
        messAlert={mess}
        status={alertStatus}
        set={setAlert}
        time={3000}
        title={alertStatus}

        />
      </Dialog>
    </div>
  );
}
