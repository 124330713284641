import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import useToken from "../../assets/useToken";
import Loader from "../Loader/Loader";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Alerts from "../Alert/Alert";
import AlertDialog from "../Dialog/Dialog";
import InfoIcon from "@mui/icons-material/Info";
import DialogAddStudents from "./DialogAdd/DialogAddStudent";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import DialogEditStudent from "./DialogAdd/DialogEditStudent";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { set } from "date-fns";

export default function Students() {
  const [dataStudents, setDataStudents] = useState();
  const [open, setOpen] = useState(false);
  const [yesOrNo, setYesOrNo] = useState(false);
  const [yesOrNoAdd, setYesOrNoAdd] = useState(false);
  const [status, setStatus] = useState();
  const [messAlert, setMessAlert] = useState();
  const [title, setTitle] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const [studentProfile, setStudentProfile] = useState();

  const [id, setId] = useState();

  const [dataClass, setDataClass] = useState();

  //EDIT
  const [openEdit, setOpenEdit] = useState(false);

  const [rerender, setRerender] = useState(false);

  //IMAGE


  const { token } = useToken();
  const Edit = (id) => () => {
    setId(id);
    setOpenEdit(true);
  };
  const Delete = (params) => () => {
    setOpen(true);
    setId(params);
  };
  const Add = (params) => () => {
  };

  useEffect(() => {

      fetch("https://api-lms.nourkadri.me/api/students/get", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => data.json())

        .then((data) => setDataStudents(data))
        .then(setRerender(false));
    
    setYesOrNoAdd(false)
    setOpen(false)
    setOpenEdit(false)
    setYesOrNo(false)
  }, [yesOrNo, yesOrNoAdd, rerender]);
  useEffect(() => {
    if (yesOrNo == true) {
      fetch(`https://api-lms.nourkadri.me/api/students/delete?id=${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wrong");
        })
        .then((responseJson) => {
          if (responseJson[0] === "Student Deleted.") {
            setMessAlert(responseJson[0]);
            setTitle("success");
            setStatus("success");
            setOpenAlert(true);
          }
          return responseJson;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setYesOrNo(false);
  }, [yesOrNo]);

  useEffect(() => {
    if (yesOrNoAdd == true) {
      fetch(`https://api-lms.nourkadri.me/api/students/create`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: studentProfile,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wrong");
        })
        .then((responseJson) => {
          if(responseJson.hasOwnProperty('classs_id')){
            setMessAlert("done");
            setTitle("Student Added");
            setStatus("success");
            setOpenAlert(true);
            setRerender(true)
          }
         
          return responseJson;
        })
        .catch((error) => {
          console.log(error);
        });
    
    }  
    setYesOrNo(false);
    setYesOrNoAdd(false);
  }, [yesOrNoAdd,alert]);
  const columns = [
    { field: "id", headerName: "id", width: 100 },
    {
      field: "Name",
      headerName: "Name",
      width: 300,
      renderCell: (params) => {
        return (
          <>
            <Avatar src={params.row.picture} />
            &nbsp;&nbsp; {params.row.first_Name}
            &nbsp;&nbsp; {params.row.last_Name}
          </>
        );
      },
    },
    // { field: "last_Name", headerName: "Last Name", width: 200 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "phone", headerName: "Phone", width: 200 },
    { field: "date_of_birth", headerName: "Date Of Birth", width: 150 },
    {
      field: "name",
      headerName: "Class Name",
      type: "actions",
      width: 200,
      getActions: (params) => [
        <>
          <p>{params.row.classes.class_name}</p>
        </>,
      ],
    },
    {
      field: "Section name",
      headerName: "Section Name",
      type: "actions",
      width: 200,
      getActions: (params) => [
        <>
          <p>{params.row.sections.section_name}</p>
        </>,
      ],
    },
    {
      field: "edit",
      headerName: "Edit name",
      type: "actions",
      width: 190,
      renderCell: (params) => {
        return (
          <Button
            onClick={Edit(params.row.id)}
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      type: "actions",
      width: 190,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={Delete(params.id)}
          >
            Delete
          </Button>
        );
      },
    },
  ];
  if (dataStudents == undefined) {
    return <Loader />;
  }

  const addStudent = () => () => {
    fetch("https://api-lms.nourkadri.me/api/class/get", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        setDataClass(responseJson);
        return responseJson;
      });
    setOpenAdd(true);
  };

  return (
    <div className="students_page">
      <div className="class_page">
        <div className="class_logo">
          <SupervisorAccountIcon sx={{ fontSize: 100, color: "blue" }} />
        </div>
      </div>
      <div className="btn_add_admin">
        <button onClick={addStudent()}>
          <AddCircleOutlineRoundedIcon sx={{ fontSize: 40, color: "blue" }} />
        </button>
      </div>
      <div className="Student_filter"></div>
      <Table rows={dataStudents} columns={columns}></Table>
      <AlertDialog
        setOpen={setOpen}
        open={open}
        yes={setYesOrNo}
        mess={"You are trying delete students! are you sure?"}
      />
      <Alerts
        title={title}
        messAlert={messAlert}
        status={status}
        set={setOpenAlert}
        open={openAlert}
        time={3000}
      />
      <DialogAddStudents
        open={openAdd}
        set={setOpenAdd}
        setStudentProfile={setStudentProfile}
        yes={setYesOrNoAdd}
        dataClass={dataClass}
        setRerender={setRerender}
      />

      <DialogEditStudent
        open={openEdit}
        set={setOpenEdit}
        dataClass={dataClass}
        id={id}
        setID={setId}
        setRerender={setRerender}
      />
    </div>
  );
}
