import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import useToken from "../../assets/useToken";
import Loader from "../Loader/Loader";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alerts from "../Alert/Alert";
import Edit from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";

const Section = () => {
  const [dataSection, setDataSection] = useState();
  const [open, setOpen] = useState(false);
  const [yesOrNo, setYesOrNo] = useState(false);
  // const [load, setLoad] = useState(false); kermel ym7e d8re mn dun ma a3ml refresh
  const [id, setId] = useState();
  //Alert
  const [alertStatus, setAlertStatus] = React.useState("error");
  const [alert, setAlert] = React.useState(false);
  const [mess, setMess] = useState();
  //Dialog
  const [diaTitle, setDiaTitle] = useState();
  const [diaContent, setDiaContent] = useState();

  //section name
  const [secName, setSecName] = useState();

  //Edit dialog
  const [openEdit, setOpenEdit] = useState(false);
  const [saveEdit, setSaveEdit] = useState(false);

  //Add dialog
  const [openAdd, setOpenAdd] = useState(false);
  const [saveAdd, setSaveAdd] = useState(false);

  //Select
  const [selectClass, setSelectClass] = useState();
  const [dataClass, setDataClass] = useState([]);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "section_name",
      headerName: "Section Name",
      width: 400,
    },
    {
      field: "name",
      headerName: "Class Name",
      type: "actions",
      width: 400,
      getActions: (params) => [
        <>
          <p>{params.row.classes.class_name}</p>
        </>
      ],
    },
    {
      field: "actions1",
      headerName: "Edit",
      type: "actions",
      width: 400,
      renderCell: (params) => {
        return (
          <Button onClick={() => Edit(params.id, params.row.section_name)} variant="contained" color="primary" startIcon={<EditIcon />}>
            Edit
          </Button>
        );
      }
    },
    {
      field: "actions2",
      headerName: "Delete",
      type: "actions",
      width: 400,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => Delete(params.id)}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const { token } = useToken();

  const fetchSections = () => {
   
  };

  useEffect(() => {
    fetch(`https://api-lms.nourkadri.me/api/section/get`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        
        setYesOrNo(false)
        setSaveAdd(false)
        return response.json();
      })
      .then((actualData) => setDataSection(actualData))
      .catch((err) => {
        console.log(err.message);
      });

  }, [yesOrNo,openEdit,saveAdd,alert  ]);

  //Delete
  useEffect(() => {
    if (yesOrNo == true) {
      fetch(`https://api-lms.nourkadri.me/api/section/delete?id=${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          // setLoad(!load);

          return response.json();
        })
        .then((responseJson) => {
          if (responseJson.error) {
            setMess(responseJson.error);
            setAlertStatus("warning");
            setAlert(true);
          }
          if (responseJson.not_exists) {
            setMess(responseJson.not_exists);
            setAlertStatus("error");
            setAlert(true);
          }
          if (responseJson.done) {
            setMess(responseJson.done);
            setAlertStatus("success");
            setAlert(true);
          }
          return responseJson;
        })

        .catch((err) => {
          console.log(err.message);
        });
      setYesOrNo(false);
    }
  }, [yesOrNo]);

  //Edit
  useEffect(() => {
    if (saveEdit == true) {
       setAlert(false);
      fetch(`https://api-lms.nourkadri.me/api/section/edit?id=${id}`, {
        method: "PUT",
        body: JSON.stringify({
          // '_method': 'PUT',
          section_name: secName,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          // setLoad(!load);

          return response.json();
        })
        .then((responseJson) => {
          if (responseJson.hasOwnProperty("section_name")) {
            setMess("class edited!");
            setAlertStatus("success");
            setAlert(true);
          }
          return responseJson;
        })

        .catch((err) => {
          console.log(err.message);
        });
      setSaveEdit(false);
      fetchSections();
    }
  }, [saveEdit]);

  //Dropdown fetch

  function fetchData() {
   
   
  }
  useEffect(() => {
    fetch(`https://api-lms.nourkadri.me/api/class/get`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
          })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        return response.json();
      })

      .then((actualData) => setDataClass(actualData))
      .catch((err) => {
        console.log(err.message);
      });
  }, [openAdd,alert]);

  if (dataSection == undefined) {
    return <Loader />;
  }
  //For Dialog
  const Delete = (id) => {
    setId(id);
    setOpen(true);
    setDiaContent("You are trying to delete a section. Are you sure ?");
    setDiaTitle("Alert");
  };

  const Edit = (id, section_name) => {
    setId(id);
    setSecName(section_name);
    setOpenEdit(true);
    setDiaTitle("Edit Section Name");
  };

  const handleYes = () => {
    setOpen(false);
    setYesOrNo(true);
  };
  const handleNo = () => {
    setOpen(false);
    setYesOrNo(false);
  };

  const handleCancel = () => {
    setOpenEdit(false);
  };

  const handleSave = () => {
    setOpenEdit(false);
    setSaveEdit(true);
  };

  const handleAdd = () => {
    setOpenAdd(true);
    setDiaTitle("Add New Section");
  };

  const handleSaveAdd = () => {
   
    fetch(
      `https://api-lms.nourkadri.me/api/section/add?section_name=${secName}&class_id=${selectClass}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        setAlertStatus("success");
        setAlert(true)
        setMess("done")
        

        return response.json();
      })

  
      setOpenAdd(false);
      setSaveAdd(true);
    fetchSections();
    
  };

  const handleCancelAdd = () => {
    setOpenAdd(false);
    setSaveAdd(false);
  };
  //dropdown (assign section to class)
  const handleClass = (event) => {
    setSelectClass(event.target.value);
  };

  if (dataSection) {
    return (
      <div className="class_page">
        <div className="class_logo">
        <DynamicFeedIcon  sx={{ fontSize: 100, color: "blue" }}/>
        </div>
        <Box sx={{ height: "71.5vh", width: "100%", marginTop: "3%" }}>
          <div className="btn_add_class">
            <button onClick={handleAdd}>
              <AddCircleOutlineRoundedIcon
                sx={{ fontSize: 40, color: "blue" }}
              />
            </button>
          </div>
          <DataGrid
            rows={dataSection}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </Box>
        <Dialog open={open}>
          <DialogTitle>{diaTitle}</DialogTitle>
          <DialogContent>{diaContent}</DialogContent>
          <DialogActions>
            <Button onClick={handleNo}>No</Button>
            <Button onClick={handleYes} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* EDIT  */}
        <Dialog open={openEdit}>
          <DialogTitle>{diaTitle}</DialogTitle>
          <DialogContent>
            {diaContent}
            <TextField
              value={secName}
              autoFocus
              margin="dense"
              id="name"
              label="Section Name"
              placeholder={secName}
              type="name"
              fullWidth
              variant="standard"
              onChange={(e) => setSecName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave} autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* ADD */}
        <Dialog open={openAdd}>
          <DialogTitle>{diaTitle}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Section Name"
              type="name"
              fullWidth
              variant="standard"
              onChange={(e) => setSecName(e.target.value)}
            />

            <Box sx={{ minWidth: 120, marginTop: "1rem" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Class</InputLabel>

                <Select
                  // labelId="demo-simple-select-label"
                  // id="demo-simple-select"
                  label="Class"
                  value={selectClass}
                  onChange={handleClass}
                >
                  {dataClass &&
                    dataClass.map((ele, i) => {
                      return (
                        <MenuItem key={i} value={ele.id}>
                          {ele.class_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveAdd}>Save</Button>
            <Button onClick={handleCancelAdd}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Alerts
          open={alert}
          messAlert={mess}
          status={alertStatus}
          set={setAlert}
          time={3000}
          title={alertStatus}
        />
      </div>
    );
  }
};{/* <DynamicFeedIcon   sx={{ fontSize: 40 }} /> */}


export default Section;
