import React, { useState, useEffect } from "react";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import useToken from "../../assets/useToken";
import "./Dashboard.css"
import Loader from "../Loader/Loader";
import CanvasJSReact from '../../assets/chart/canvasjs.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
export default function Dashboard(props) {
  
  const [students, setStudents] = useState();
  const [sections, setSections] = useState();
  const [classes, setClasses] = useState();
  const { token } = useToken();
  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2", //"light1", "dark1", "dark2"
    title:{
      text: "Number of Students from 2017 till 2022"
    },
    axisY: {
      includeZero: true
    },
    data: [{
      type: "column", //change type to bar, line, area, pie, etc
      //indexLabel: "{y}", //Shows y value on all Data Points
      indexLabelFontColor: "#5A5757",
      indexLabelPlacement: "outside",
      dataPoints: [
        { label: "2017",  y: 15  },
        { label: "2018", y: 35  },
        { label: "2019", y: 45  },
        { label: "2020",  y: 50  },
        { label: "2021",  y: 58  },
        { label: "2022",  y: students  }

      ]
    }]
  }
  useEffect(() => {
    fetch("https://api-lms.nourkadri.me/api/reports/count", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        setClasses(responseJson.classes)
        setSections(responseJson.sections)
        setStudents(responseJson.students)
        return responseJson;
      })
      
  },[]);
  if (students == undefined) {
    return <Loader />;
  }
  return(
    <>
    	<div className="main-section">
		<div className="dashbord">
			<div className="icon-section">
				<SupervisorAccountIcon  sx={{ fontSize: 100 }}/><br></br>
				<small>Students</small>
				<p>{students}</p>
			</div>
			<div className="detail-section">
			</div>
		</div>
		<div className="dashbord dashbord-orange">
			<div className="icon-section">
      <MenuBookIcon  sx={{ fontSize: 100 }}/><br></br>
				<small>Classes</small>
				<p>{classes}</p>
			</div>
			<div className="detail-section">
			</div>
		</div>
		<div className="dashbord dashbord-red">
			<div className="icon-section">
      <DynamicFeedIcon  sx={{ fontSize: 100 }}/><br></br>
				<small>Sections</small>
				<p>{sections}</p>
			</div>
			<div className="detail-section">
			</div>
		</div>
    <div className="chart_user">
			<CanvasJSChart options = {options} 
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
	</div>
    </>
  );
}
