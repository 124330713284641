import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import avatar from "../../../assets/images/blank-profile-pic.png";
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
import $ from "jquery";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useToken from "../../../assets/useToken";
import { styled } from "@mui/material/styles";

export default function DialogAddStudents(props) {
  const formData = new FormData();
  const { token } = useToken();
  const [firstName, setFirstName] = React.useState();
  const [image, setImage] = React.useState(avatar);
  const [lastName, setLastName] = React.useState();
  const [phone, setPhone] = React.useState();
  const [email, setEmail] = React.useState();
  const [dob, setDob] = React.useState();
  const [pic, setPic] = React.useState();
  const [enable, setEnable] = React.useState(true);

  //bi alb l add
  const [dataSection, setDataSection] = React.useState([]);
  const [selectClass, setSelectClass] = useState();
  const [selectSection, setSelectSection] = useState();

  useEffect(() => {}, []);
  //fetch section for dropdown in add

  const handleImage = (e) => {
    setPic(e.target.files[0]);
    const [file] = e.target.files;
    setImage(URL.createObjectURL(file));
  
  }
  const handleSave = () => {
    formData.append("first_Name", firstName);
    formData.append("last_Name", lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("date_of_birth", dob);
    formData.append("picture", pic);

    formData.append("classs_id", selectClass);

    formData.append("section_id", selectSection);

    props.setStudentProfile(formData);
    props.yes(true);
    props.set(false);
    setImage(avatar)
  };

  const handleClass = (event) => {
    setSelectClass(event.target.value);
    fetch(
      `https://api-lms.nourkadri.me/api/section/getSectionInClass?id=${event.target.value}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        setDataSection(responseJson);

        return responseJson;
      });
    setEnable(false);
  };

  const handleSection = (event) => {
    setSelectSection(event.target.value);
  };

  const handleCancel = () => {
    props.set(false);
  };
  if (props.dataClass !== undefined) {
    return (
      <div>
        <Dialog open={props.open} onClose={handleCancel}>
          <DialogTitle>{props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{props.description}</DialogContentText>
            <div className="avatar-upload">
              <div className="avatar-edit">
                <input
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleImage}
                />
                <label htmlFor="imageUpload">
                  <FaceRetouchingNaturalIcon
                    sx={{ fontSize: 35, color: "blue" }}
                  />
                </label>
              </div>
              <div className="avatar-preview">
                <div
                  id="imagePreview"
                  style={{ backgroundImage: `url(${image})` }}
                ></div>
              </div>
            </div>
            <TextField
              autoFocus
              margin="dense"
              id="first_name"
              label="First Name"
              placeholder="First Name"
              type="name"
              fullWidth
              variant="standard"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="last_name"
              label="Last Name"
              placeholder="Last Name"
              type="name"
              fullWidth
              variant="standard"
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email"
              placeholder="Email"
              type="name"
              fullWidth
              variant="standard"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              id="phone"
              label="Phone"
              placeholder="Phone"
              type="phone"
              fullWidth
              variant="standard"
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextField
              margin="dense"
              id="date_of_birth"
              type="date"
              fullWidth
              variant="standard"
              onChange={(e) => setDob(e.target.value)}
            />
            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Class
              </InputLabel>
              <Select
                defaultValue=""
                labelId="class"
                id="class"
                onChange={handleClass}
                autoWidth
                label="Classes"
              >
                {props.dataClass.map((classs) => (
                  <MenuItem value={classs.id} key={classs.class_name}>
                    {classs.class_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Section
              </InputLabel>
              <Select
                defaultValue=""
                labelId="section"
                id="section"
                onChange={handleSection}
                autoWidth
                label="Sections"
                disabled={enable}
              >
                {dataSection.map((sec) => (
                  <MenuItem value={sec.id}>{sec.section_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
