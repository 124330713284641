import "./Login.css";
import React, { useState } from "react";
import PropTypes from "prop-types";
import SchoolIcon from '@mui/icons-material/School';
import Alerts from "../Alert/Alert";
import bg from "../../assets/images/bg.png"
import styled from "styled-components";


async function loginUser(credentials) {

  return fetch("https://api-lms.nourkadri.me/api/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}
const Content = styled.section`
border: 1px solid #000;
background-color: #21D4FD;
background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);
background-position: center;
width: 100%;
background-size: cover;

`;
export default function Login({ setToken }) {
  
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const handleClick = () => {
    setOpen(true);
  };

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [mess, setMess] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await loginUser({
      email,
      password,
    });
    if (data.success === false) {
      setMess(data.message);
      setStatus("error")
      return handleClick();
    }
    if (data.success === true) {
      setMess(data.message);
      setStatus("success")
      setToken(data)
       return handleClick();
    }
    if (data.error.password) {
      setStatus("warning")
      setMess(data.error.password);
     return handleClick();
    }
    if (data.error.email) {
      setStatus("warning")
      setMess(data.error.email);
      return handleClick();
    }
  };
  
 
  return (
    <Content >
      <form onSubmit={handleSubmit} id="main">
      <Alerts open={open} messAlert={mess} 
        status={status} 
        set={setOpen} time={3000}
        title={status}
         />
        <div className="logo_dashboard"> <SchoolIcon sx={{ fontSize: 60,color: "black"}}/></div>
        <h2 className="login_title">Login to LMS </h2>

        <div className="input-parent">
          <label htmlFor="username">Email</label>
          <input
            type="text"
            id="username"
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>

        <div className="input-parent">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>

        <button className="btn_login" type="submit">Login</button>
      </form>
      
    </Content>
  );
}
Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
