import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import Logout from "../Logout/Logout";
import { Link } from "react-router-dom";
import "./Sidebar.css";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar(props) {
  const data = localStorage.getItem("token");
  const profile = JSON.parse(data);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={profile.user.name}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={profile.user.name}
                  sx={{ width: "50px", height: "50px" }}
                  src={profile.user.picture}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <h3 className="name_profile">{profile.user.name}</h3>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key="Dashboard" disablePadding>
            <Link to={"/"} style={{ textDecoration: "none", color: "black" }}>
              <ListItemButton sx={{paddingBottom:5 }}>
                <ListItemIcon>
                  <DashboardCustomizeIcon sx={{ fontSize: 40}} />
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key="Admins" disablePadding>
            <Link
              to={"/admins"}
              style={{ textDecoration: "none", color: "black" }}
            >
            <ListItemButton sx={{paddingBottom:5 }}>
                <ListItemIcon>
                  <AdminPanelSettingsIcon sx={{ fontSize: 40 }} />
                </ListItemIcon>
                <ListItemText primary={"Admins"} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key="Students" disablePadding>
          <Link to={"/students"} style={{ textDecoration: "none", color: "black" }}>
          <ListItemButton sx={{paddingBottom:5 }}>
              <ListItemIcon>
                <SupervisorAccountIcon sx={{ fontSize: 40 }} />
              </ListItemIcon>
              <ListItemText primary={"Students"} />
            </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key="Classes" disablePadding>
            <Link
              to={"/classes"}
              style={{ textDecoration: "none", color: "black" }}
            >
           <ListItemButton sx={{paddingBottom:5 }}>
                <ListItemIcon>
                  <MenuBookIcon sx={{ fontSize: 40 }} />
                </ListItemIcon>
                <ListItemText primary={"Classes"} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key="Sections" disablePadding>
          <Link
              to={"/sections"}
              style={{ textDecoration: "none", color: "black" }}
            >
            <ListItemButton sx={{paddingBottom:5 }}>
              <ListItemIcon>
                <DynamicFeedIcon sx={{ fontSize: 40 }} />
              </ListItemIcon>
              <ListItemText primary={"Sections"} />
            </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key="Attendance" disablePadding>
            <Link
              to={"/attendances"}
              style={{ textDecoration: "none", color: "black" }}
            >
            <ListItemButton sx={{paddingBottom:5 }}>
                <ListItemIcon>
                  <NotificationsActiveIcon sx={{ fontSize: 40 }} />
                </ListItemIcon>
                <ListItemText primary={"Attendance"} />
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem key="Reports" disablePadding>
            <Link
              to={"/reports"}
              style={{ textDecoration: "none", color: "black" }}
            >
           <ListItemButton sx={{paddingBottom:5 }}>
                <ListItemIcon>
                  <AssessmentIcon sx={{ fontSize: 40 }} />
                </ListItemIcon>
                <ListItemText primary={"Reports"} />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        <List>
          <Divider />
          <ListItem key="Logout" disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {props.put}
      </Box>
    </Box>
  );
}
