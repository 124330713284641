import React from "react";
import Dialog from "@mui/material/Dialog";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import LMS from "../../../assets/images/LMS.png"
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";



export default function CardInfo(props) {

  const handleCancel = () => {
   props.set(false)
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleCancel}>
      <Card sx={{ maxWidth: 345, }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="350"
          image={LMS}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Class  {props.className}
          </Typography>
          <Typography variant="body2" color="text.secondary">
This Class has <strong>{props.nm}</strong> students
<br></br>
Created at <strong>{props.createdAtt}</strong>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    <DialogActions>
          <Button onClick={handleCancel}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
