    import './Loader.scss'
import SchoolIcon from '@mui/icons-material/School';
export default function Loader(props) {
  


  return (
    <div className="card">
    <h1><SchoolIcon sx={{ fontSize: 90,color: "black"}}/></h1>
    <p className='p_wait'>Please Wait!</p>
    <div className="loader">
      <div className="spin"></div>
      <div className="bounce"></div>
    </div>
  </div>
  );
}
