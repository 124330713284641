import React, { useState, useEffect } from "react";
import useToken from "../../assets/useToken";
import Table from "../Table/Table";
import Loader from "../Loader/Loader";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Alerts from "../Alert/Alert";
import AlertDialog from "../Dialog/Dialog";
import DialogFormEdit from "./DialogFormEdit/DialogFormEdit";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DialogFormAdd from "./DilogFormAdd/DialogFormAdd";
import InfoIcon from "@mui/icons-material/Info";
import CardInfo from "./Card/Card";
import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";

import "./Class.css"
export default function Class() {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [alertStatus, setAlertStatus] = React.useState("error");
  const [dataClass, setDataClass] = useState();
  const [mess, setMess] = useState();
  const [className, setClassName] = useState();
  const [nm, setNm] = useState();
  const [openFormEdit, setOpenFormEdit] = useState();
  const [openFormAdd, setOpenFormAdd] = useState();
  const [yesOrNoEdit, setYesOrNoEdit] = useState();
  const [yesOrNoAdd, setYesOrNoAdd] = useState();
  const [openCard, setOpenCard] = useState();
  const [createdAt, setCreatedAt] = useState();
  const { token } = useToken();
  const [yesOrNo, setYesOrNo] = React.useState(false);
  const [id, setId] = useState();
  

  useEffect(() => {
    fetch("https://api-lms.nourkadri.me/api/class/get", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        setDataClass(responseJson);
        if (responseJson.hasOwnProperty('status')){
          setMess(responseJson.status);
          setAlertStatus("error");
          setAlert(true);
        }
        return responseJson;
      })
      
  },[yesOrNo,yesOrNoEdit,yesOrNoAdd]);
 
  useEffect(() => {
    if (yesOrNoEdit === true) {  
      fetch(`https://api-lms.nourkadri.me/api/class/edit?id=${id}&class_name=${className}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wrong");
        })
        .then((responseJson) => {
          if (responseJson.hasOwnProperty("id")) {
            setMess(responseJson.class_name+ " updated!");
            setAlertStatus("success");
            setAlert(true);
          }
          if (responseJson.hasOwnProperty("error")) {
            setAlertStatus("error");
            if (responseJson.error.class_name !== undefined) {
              setMess(responseJson.error.class_name);
            }
            if (responseJson.error.id !== undefined) {
              setMess(responseJson.error.id);
            }
            setAlert(true);
          }

          return responseJson;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setYesOrNoEdit(false);
  }, [yesOrNoEdit]);
  useEffect(() => {
    if (yesOrNo === true) {
      fetch(`https://api-lms.nourkadri.me/api/class/delete?id=${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Something went wrong");
          })
          .then((responseJson) => {
              if (responseJson.error_delete){
                  setMess(responseJson.error_delete)
                  setAlertStatus("warning")
                  setAlert(true)
              }
              if (responseJson.not_exists){
                  setMess(responseJson.not_exists)
                  setAlertStatus("error")
                  setAlert(true)
              }
              if (responseJson.done){
                  setMess(responseJson.done)
                  setAlertStatus("success")
                  setAlert(true)
              }
            return responseJson;
          })
          .catch((error) => {
            console.log(error);
          });
          setYesOrNo(false)
  }

  }, [yesOrNo]);
  useEffect(() => {
    if (yesOrNoAdd === true) {
        fetch(`https://api-lms.nourkadri.me/api/class/add?class_name=${className}`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
              throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                if (responseJson.hasOwnProperty('error')){
                    setMess(responseJson.error.class_name)
                    setAlertStatus("error")
                    setAlert(true)
                }
                if (responseJson.hasOwnProperty('id')){
                    setMess(responseJson.class_name+" added!")
                    setAlertStatus("success")
                    setAlert(true)
                }
                setClassName("")
              return responseJson;
            })
            .catch((error) => {
              console.log(error);
            });
            setYesOrNoAdd(false)
    }
  }, [yesOrNoAdd]);
  const Delete = (id) => () => {
    setId(id);
    setOpen(true);
    
  };
  const Edit = (class_name,id) => () => {
    setClassName(class_name)
    setId(id)
    setOpenFormEdit(true)
  };
  const Add = () => () => {
    setOpenFormAdd(true)
  };
  const OpenCard = (params) => () => {
    fetch(`https://api-lms.nourkadri.me/api/class/id?id=${params.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
       setNm(responseJson.studentsNumber)
      })
    setClassName(params.class_name)
    const after = params.created_at.substring(0,10);
    setCreatedAt(after)
    setId(params.id)
    setOpenCard(true)
  };
  
  const columnsClass = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "class_name",
      headerName: "Class name",
      width: 300,
    },
    {
      field: "Date",
      headerName: "Date and Time Create",
      type: "actions",
      width: 300,
      renderCell: (params) => {
        return (
        <>
          {params.row.created_at.slice(0, -11)}
        </>
        );
      },
    },
    {
      field: "Date1",
      headerName: "Date and Time Update",
      type: "actions1",
      width: 300,
      renderCell: (params) => {
        return (
        <>
          {params.row.updated_at.slice(0, -11)}
        </>
        );
      },
    },

    {
      field: "actions1",
      headerName: "Edit name",
      type: "actions",
      width: 190,
      renderCell: (params) => {
        return (
          <Button  onClick={Edit(params.row.class_name,params.row.id)} variant="contained" color="primary" startIcon={<EditIcon />}>
            Edit
          </Button>
        );
      }
    },
    {
      field: "actions",
      headerName: "Delete",
      type: "actions",
      width: 300,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={Delete(params.id)}
          >
            Delete
          </Button>
        );
      },
    },
    {
      field: "info",
      headerName: "Information",
      type: "actions",
      width: 200,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<InfoIcon sx={{ fontSize: 25, color: "green" }} />}
          label="info"
          onClick={OpenCard(params.row)}
        />,
      ],
    },
    
  ];
  if (dataClass === undefined) {
    return <Loader />;
  }
  if (dataClass) {
    return (
      <div className="class_page">
        <div className="class_logo">
          <MenuBookIcon
            sx={{ fontSize: 100, color: "blue" }}
          />
        </div>
        <div className="btn_add_class">
          <button onClick={Add()}>
            <AddIcon sx={{ fontSize: 40, color: "blue" }} />
          </button>
        </div>
        <Table rows={dataClass} columns={columnsClass} />
        <AlertDialog
          mess={"You are trying delete Class ! are you sure?"}
          open={open}
          setOpen={setOpen}
          yes={setYesOrNo}
        />
        <Alerts
          open={alert}
          messAlert={mess}
          status={alertStatus}
          set={setAlert}
          time={3000}
          title={alertStatus}
        />
         <DialogFormEdit
          set={setOpenFormEdit}
          yes={setYesOrNoEdit}
          open={openFormEdit}
          title={"Edit Class Name"}
          description={"Type here your information "}
          className={className}
          setClassName={setClassName}
        />
          <DialogFormAdd
          set={setOpenFormAdd}
          yes={setYesOrNoAdd}
          open={openFormAdd}
          title={"Add Class Name"}
          description={"Type here your information "}
          className={className}
          setClassName={setClassName}
        />
        <CardInfo
          open={openCard}
          set={setOpenCard}
          className={className}
          createdAtt={createdAt}
          nm={nm}
        />
      </div>
      
    );
  }
}
