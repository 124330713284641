import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
import "./DialogForm.scss";
import $ from "jquery";
const Input = styled("input")({
  display: "none",
});



export default function DialogForm(props) {
  function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $("#imagePreview").css(
          "background-image",
          "url(" + e.target.result + ")"
        );
        $("#imagePreview").hide();
        $("#imagePreview").fadeIn(650);
      };
      reader.readAsDataURL(input.files[0]);
    }
  } 
  $("#imageUpload").change(function () {
    readURL(this);
  });
  async function handleChange(e) {
 
    const formData = new FormData();
    formData.append("picture", e.target.files[0]);
    formData.append("id", props.id);
    props.FormPic(formData);
    props.yes(true);
  }
  const handleSave = () => {
    const formData = new FormData();
    formData.append("id", props.id);
    formData.append("name", props.name);
    formData.append("email", props.email);
    props.FormPic(formData);
    props.yes(true);
    props.set(false);
  };

  const handleCancel = () => {
    props.set(false);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{props.title}</DialogTitle>
        <div className="container">
          <div className="avatar-upload">
            <div className="avatar-edit">
              <input
                type="file"
                id="imageUpload"
                onChange={handleChange}
                accept=".png, .jpg, .jpeg"
              />
              <label htmlFor="imageUpload">
                <FaceRetouchingNaturalIcon
                  sx={{ fontSize: 35, color: "blue" }}
                />
              </label>
            </div>
            <div className="avatar-preview">
              <div
                id="imagePreview"
                style={{ backgroundImage: `url(${props.picture})` }}
              ></div>
            </div>
          </div>
        </div>
        <h2 className="change_photo_title">Change your Photo</h2>

        <DialogContent>
          <DialogContentText>{props.description}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            placeholder={props.name}
            type="name"
            fullWidth
            variant="standard"
            onChange={(e) => props.setName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            placeholder={props.email}
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => props.setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
