import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useToken from "../../assets/useToken";
import Loader from "../Loader/Loader";
import Table from "../Table/Table";
import DialogEditAttendance from "./DialogEditAttemdance";

import "./Attendance.css";
import DeleteIcon from "@mui/icons-material/Delete";
import Alerts from "../Alert/Alert";
import EditIcon from "@mui/icons-material/Edit";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShowAttendanceDialog(props) {
  const { token } = useToken();
  const [value, setValue] = React.useState(new Date());
  const [students, setStudents] = React.useState([]);
  const [alert, setAlert] = React.useState(false);
  const [alertStatus, setAlertStatus] = React.useState();
  const [mess, setMess] = useState();
  const [id, setId] = useState();
  const [status, setStatus] = useState();
  const [openFormEdit, setOpenFormEdit] = useState();
  const [openFormAdd, setOpenFormAdd] = useState();
  const [yesOrNoEdit, setYesOrNoEdit] = useState();
  const handleClose = () => {
    props.set(false);
  };
  const Delete = (params) =>()=> {
    fetch(
      `https://api-lms.nourkadri.me/api/attendance/delete?id=${params.row.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        
        setMess(responseJson.error);
        setAlertStatus("success");
        setAlert(true);
        return responseJson;
      });
    setStudents(students.filter((row) => row.id !== params.row.id));
  };

  const Edit = (params) =>()=> {
    setOpenFormEdit(true)
    setId(params.id)
  };

  const handleChange = (event) => {
    setStudents([]);
    fetch(
      `https://api-lms.nourkadri.me/api/attendance/getbydate?section_id=${
        event.target.value
      }&date=${value.toLocaleDateString("sv-SE")}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        setStudents(responseJson);
        return responseJson;
      });
      
  };

  const columns = [
    { field: "id", headerName: "ID Attendance", width: 100 },
    { field: "student_id", headerName: "ID Student", width: 100 },
    {
      field: "name",
      headerName: "Student Name",
      type: "actions",
      width: 300,
      renderCell: (params) => {
        return (
        <>
          {params.row.first_Name} {params.row.last_Name}
        </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 300,
      renderCell: (params) => {
        return (
          <>
            {params.row.status == "late" ? (
              <span style={{ backgroundColor: "yellow" }} className="dot"></span>
            ) : null}
            {params.row.status == "attend" ? (
              <span style={{ backgroundColor: "green" }} className="dot"></span>
            ) : null}
            {params.row.status == "absent" ? (
              <span style={{ backgroundColor: "red" }} className="dot"></span>
            ) : null}
            &nbsp;&nbsp; {params.row.status}
          </>
        );
      },
    },
    {
      field: "Date",
      headerName: "Date and Time",
      type: "actions",
      width: 300,
      renderCell: (params) => {
        return (
        <>
          {params.row.updated_at.slice(0, -11)}
        </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Delete",
      type: "actions",
      width: 300,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={Delete(params)}
          >
            Delete
          </Button>
        );
      },
    },
    {
      field: "actions1",
      headerName: "Edit",
      type: "actions",
      width: 190,
      renderCell: (params) => {
        return (
          <Button
            onClick={Edit(params.row)}
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
        );
      },
    },
   
  ];
  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Show Attendance
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <FormControl sx={{ m: 1, minWidth: 110 }}>
            <InputLabel id="class">Class</InputLabel>
            <Select
              defaultValue=""
              labelId="class"
              id="class"
              onChange={props.handleChange}
              autoWidth
              label="Classes"
            >
              {props.classes.map((cl) => (
                <MenuItem value={cl.id} key={cl.class_name}>
                  {cl.class_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 110 }}>
            <InputLabel id="section">Section</InputLabel>
            <Select
              defaultValue=""
              labelId="section"
              id="section"
              onChange={handleChange}
              autoWidth
              label="Sections"
              disabled={props.enable}
            >
              {props.sections.map((s) => (
                <MenuItem value={s.id} key={s.section_name}>
                  {s.section_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DatePicker
                disableFuture
                label="Responsive"
                openTo="day"
                views={["day", "month", "year"]}
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: "13.5%" }} {...params} />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </List>
        <Table rows={students} columns={columns} />
      </Dialog>
      <Alerts
          open={alert}
          messAlert={mess}
          status={alertStatus}
          set={setAlert}
          time={3000}
          title={alertStatus}
        />
        
        <DialogEditAttendance
          set={setOpenFormEdit}
          yes={setYesOrNoEdit}
          open={openFormEdit}
          title={"Edit Attendance"}
          description={"choose a new status "}
          id={id}
        />
    </div>
  );
}
